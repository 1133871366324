import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import TriggersAccording from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger_accordion';
import ActionsAccording from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/actions_accordion';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud IFTTT Triggers & Actions",
  "path": "/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/",
  "dateChanged": "2020-10-21",
  "author": "Mike Polinowski",
  "excerpt": "On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.",
  "image": "../MD_SearchThumb_Cloud_IFTTT.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-INSTAR-Cloud-IFTTT_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR Cloud IFTTT Triggers & Actions' dateChanged='2020-10-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.' image='/images/Search/MD_SearchThumb_Cloud_IFTTT.png' twitter='/images/Search/MD_SearchThumb_Cloud_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "instar-triggers--actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-triggers--actions",
        "aria-label": "instar triggers  actions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Triggers & Actions`}</h2>
    <h3 {...{
      "id": "triggers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#triggers",
        "aria-label": "triggers permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Triggers`}</h3>
    <TriggersAccording mdxType="TriggersAccording" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#actions",
        "aria-label": "actions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Actions`}</h3>
    <ActionsAccording mdxType="ActionsAccording" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      